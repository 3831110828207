<template>
  <div class="shell">
    <div class="Carousel">
      <img :src="bannerList.banner" />
    </div>
    <div class="music">
      <div v-for="(item, index) in DemoseaList" :key="index" class="listbox">
        <DemoSea :DemoseaList="item" creatType="none"></DemoSea>
      </div>
    </div>
  </div>
</template>

<script>
import DemoSea from "@/components/content/DemoSea";
import { getCase } from "@/api/Home";
export default {
  props: {},
  data() {
    return {
      code:'',
      bannerList:[],
      CollectionList: [
      ],
         DemoseaList: [
      ],
    }
  },
    components: {
    DemoSea,
  },
  computed: {},
  created() {
    this.code = this.$route.query.code
    this.getCaseList(); // getCaseList列表
    console.log(this.code,' this.code')
   },
  mounted() {
  },
  watch: {},
  methods: {
  // getCaseList列表
  getCaseList() {
      getCase({
        // code: 'REF011'
		code: this.code
      }).then((res) => {
        this.bannerList = res.data.banner
		let arr = []
		arr = res.data.list.map(item => {
			return {
				...item,
				titlecontent: '歌曲详情',
				code: this.bannerList.code
			}
		})
        this.DemoseaList = arr
          console.log( this.DemoseaList, "DemoseaList");
        })
        .catch((err) => {
          console.log(err, "列表失败");
        });
    },
  },
}

</script>
<style lang="less" scoped>
.shell {
  width: 100%;
  height: auto;

  .Carousel {
    width: 100%;
    height: 350px;
    margin-top: 24px;

    img {
      width: 100%;
      height: 350px;
    }

  }

  .Reference {
    width: 117px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 33px;
    margin-top: 20px;
    color: #ffffff;
  }

  .music {
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .listbox{
      width: 100%;
	  
    .list {
      margin-bottom: 20px;
      width: 30%;

    }
    }
	::v-deep .listbox .box .box-rg .box-top{
		height: 0 !important;
	}
	::v-deep .listbox .box .box-rg .box-top .username{
		height: 0 !important;
	}
	::v-deep .listbox .box .box-rg .box-bottom{
		height: 100% !important;
	}
	
  }
}
</style>


